<template>
  <div>
    <a-spin :spinning="isloading" tip="加载中...">
      <a-row>
        <a-col :span="4">
          <a-tree
            :replaceFields="replaceFields"
            :treeData="treeDataCategory"
            @select="selectCategory"
          />
        </a-col>
        <a-col :span="20">
          <div class="pxkeji-right-box" style="margin-top: 0">
            <a-form class="ant-advanced-search-form">
              <a-row :gutter="24">
                <a-col :span="8">
                  <a-form-item label="资讯名称">
                    <a-input
                      placeholder="请输入资讯名称"
                      v-model="where.keyword.title"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="8">
                  <a-button type="primary" @click="searchList">搜索</a-button>
                  <a-button :style="{ marginLeft: '8px' }" @click="clear"
                    >清空</a-button
                  >
                </a-col>
              </a-row>
            </a-form>
          </div>
          <div class="pxkeji-right-box" style="margin-top: 0">
            <a-table
              rowKey="id"
              :columns="list.columns"
              :pagination="list.pagination"
              :dataSource="list.datas"
              @change="onChange"
              :loading="isloading"
              bordered
              :rowSelection="{
                type: 'radio',
                onChange: onRowChange,
                selectedRowKeys: selectRows,
              }"
              size="small"
              :locale="{ emptyText: '暂无数据' }"
            >
              <span slot="personinfo" slot-scope="personinfo"
                >{{ personinfo.contacts }},{{
                  personinfo.contactsTelephone
                }}</span
              >
            </a-table>
          </div>
          <a-row :gutter="24">
            <a-col :span="24" :style="{ textAlign: 'center' }">
              <a-button type="primary" @click="choseBack">选择并返回</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "编号",
  },
  {
    dataIndex: "title",
    key: "title",
    align: "center",
    title: "资讯标题",
    width: "23%",
  },
  {
    dataIndex: "tag",
    key: "tag",
    align: "center",
    title: "标签",
  },
  {
    dataIndex: "articlecategoryName",
    key: "articlecategoryName",
    align: "center",
    title: "栏目",
  },
  {
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
    title: "提交时间",
  },
];
export default {
  props: {
    id: String,
  },
  data() {
    return {
      treeDataCategory: [],
      replaceFields: { children: "children", title: "name", key: "id" },
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          showTotal: function (total) {
            return "共 " + total + " 条";
          },
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          title: "",
        },
      },
      selectRows: [], //选中项编号
      selectdata: {}, //选择撤机选项
      isloading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getTree();
      this.GetList();
      if (this.id) this.selectRows = [this.id];
    });
  },
  methods: {
    buildTree(all, parentid) {
      var list = [];
      for (var i = 0; i < all.length; ++i) {
        var item = all[i];

        if (item.parentid == parentid) {
          var childs = this.buildTree(all, item.id);
          list.push({
            name: item.name,
            parentid: parentid,
            id: item.id,
            description: item.description,
            status: item.isshow,
            children: childs,
          });
        }
      }
      return list;
    },
    getTree() {
      this.$api.articlecategory
        .getTree(1)
        .then((res) => {
          if (res.errorCode == this.$msg.responseCode.success) {
            // var list = this.buildTree(res.data, 0);
            this.treeDataCategory = res.data;
          } else {
            this.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    GetList() {
      this.isloading = true;
      this.clearRows();
      this.clearRows();
      let queryData = { ...this.where.keyword };
      queryData.categoryId = queryData.cateIds;
      delete queryData.cateIds;
      this.$api.article
        .getPageList(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          queryData
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    selectCategory(val) {
      if (val && val.length > 0) {
        this.where.keyword.cateIds = val[0];
        this.GetList();
      }
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    //table 单选
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectRows = selectedRowKeys;
      this.selectdata = selectedRows;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },
    //选择并返回
    choseBack() {
      if (this.selectRows) {
        this.$emit("callbackMethod", this.selectdata[0]);
      }
    },
  },
};
</script>
<style>
</style>