<template>
  <div>
    <a-spin :spinning="isloading" tip="加载中...">
      <div class>
        <div class="pxkeji-right-box" style="margin-top:0">
          <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="班级名称">
                  <a-input placeholder="请输入班级名称" v-model="where.keyword.name" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-button type="primary" @click="searchList">搜索</a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="clear">清空</a-button>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="pxkeji-right-box" style="margin-top:0">
          <a-table
            :columns="list.columns"
            :rowSelection="{type:'radio',onChange:onRowChange,selectedRowKeys:selectRows}"
            rowKey="id"
            :pagination="list.pagination"
            @change="onChange"
            :dataSource="list.datas"
            :loading="isloading"
            bordered
            size="small"
            :locale="{emptyText:'暂无数据'}"
          >
            <span slot="status" slot-scope="status">
            <a-tag v-if="status==0" color="purple">草案</a-tag>
            <a-tag v-else-if="status==1" color="green">审核中</a-tag>
            <a-tag v-else-if="status==2" color="green">审核通过</a-tag>
            <a-tag v-else-if="status==3" color="blue">报名中</a-tag>
            <a-tag v-else-if="status==4" color="purple">待开班</a-tag>
            <a-tag v-else-if="status==5" color="green">进行中</a-tag>
            <a-tag v-else-if="status==6" color="red">已结业</a-tag>
            <a-tag v-else-if="status==11" color="red">审核拒绝</a-tag>
          </span>
          </a-table>
        </div>
        <a-row :gutter="24">
          <a-col :span="24" :style="{ textAlign: 'center' }">
            <a-button type="primary" @click="choseBack">选择并返回</a-button>
          </a-col>
        </a-row>
      </div>
    </a-spin>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex:"id",
    key: "id",
    align: "left",
    title: "班级编号"
  },
  {
    dataIndex:"name",
    key: "name",
    align: "left",
    title: "培训班级"
  },
  {
    dataIndex: "studentCount",
    key: "studentCount",
    align: "center",
    title: "计划招生数量"
  },
  {
    dataIndex: "teacherName",
    key: "teacherName",
    align: "center",
    title: "班主任"
  },
  {
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
    title: "开始日期"
  },
  {
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
    title: "结束日期"
  },
  {
    dataIndex: "status",
    key: "status",
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "status" }
  },
];
export default {
  props: {
    id: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          //分页器
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          showTotal: function (total) {
            return "共 " + total + " 条";
          },
          defaultCurrent: 1, //页码
          pageSize: 10, //页容
        },
      },
      where: {
        //查询条件
        keyword: {
          //搜索条件
          name: "",
        },
      },
      selectRows: [], //选中项编号
      selectdata: {}, //选择撤机选项
      isloading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.GetList();
      if (this.id) this.selectRows = [this.id];
    });
  },
  methods: {
    GetList() {
      this.isloading = true;
      this.clearRows();
      this.$api.trainClass
        .getPagedList(
          this.list.pagination.defaultCurrent,
          this.list.pagination.pageSize,
          this.where.keyword.name,
          3
        )
        .then((res) => {
          this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            this.list.datas = res.data.records;
            this.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          this.isloading = false;
        });
    },
    onChange(page) {
      //页面切换回调
      this.list.pagination.defaultCurrent = page.current;
      this.GetList();
    },
    //table 单选
    onRowChange(selectedRowKeys, selectedRows) {
      this.selectRows = selectedRowKeys;
      this.selectdata = selectedRows;
    },
    clearRows() {
      //清空选中项
      this.selectRows = [];
    },
    searchList() {
      this.list.pagination.defaultCurrent = 1;
      this.GetList();
    },
    clear() {
      //清空搜索条件
      for (var info in this.where.keyword) {
        this.where.keyword[info] = "";
      }
    },
    //选择并返回
    choseBack() {
      if (this.selectRows) {
        this.$emit("callbackMethod", this.selectdata[0]);
      }
    },
  },
};
</script>
<style>
</style>