<template>
  <div>
    <a-row>
      <a-col :span="4">
        <a-divider orientation="left">课程分类</a-divider>
        <a-tree
          :replaceFields="replaceFields"
          :treeData="treeDataCategory"
          @select="selectCategory"
        />
      </a-col>
      <a-col :span="20">
        <div class="pxkeji-right-box" style="margin-top: 0">
          <a-form class="ant-advanced-search-form">
            <a-row>
              <a-col :span="8">
                <a-form-item label="课程名称">
                  <a-input
                    placeholder="请输入课程名称"
                    v-model="where.keyword.name"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item style="margin-left: 16px">
                  <a-button type="primary" @click="searchList">搜索</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div class="pxkeji-right-box" style="margin-top: 0">
          <a-table
            rowKey="id"
            :columns="list.columns"
            :pagination="list.pagination"
            @change="onPageChange"
            :dataSource="list.datas"
            :loading="isloading"
            bordered
            :rowSelection="{
              type: 'radio',
              onChange: onRowChange,
              selectedRowKeys: selectRows,
            }"
            size="small"
            :locale="{ emptyText: '暂无数据' }"
          >
          </a-table>
        </div>
        <a-row>
          <a-col :span="24" :style="{ textAlign: 'center' }">
            <a-button type="primary" @click="choseBack">选择并返回</a-button>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
var that;
const columns = [
  {
    dataIndex: "name",
    align: "center",
    title: "课程名称",
  },

  {
    dataIndex: "typeName",
    align: "center",
    title: "课程分类",
  },
  {
    dataIndex: "presentPrice",
    align: "center",
    title: "价格/元",
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "发布日期",
  },
];
export default {
  props: {
    id: String,
  },
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          defaultCurrent: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
      },
      where: {
        keyword: {
          name: "",
        },
      },
      replaceFields: { children: "children", title: "name", key: "id" },
      treeDataCategory: [],
      selectRows: [],
      selectdata: {},
      isloading: false,
    };
  },
  mounted() {
    that = this;
    that.GetCourseTypes();
    that.GetList();
    console.log(that.id);
    if (that.id) that.selectRows = [that.id];
  },
  methods: {
    GetList() {
      that.isloading = true;
      that.clearRows();
      that.$api.course
        .getPageList(
          that.list.pagination.defaultCurrent,
          that.list.pagination.pageSize,
          0,
          that.where.keyword.typeId,
          that.where.keyword.courseName,
          1
        )
        .then((res) => {
          that.isloading = false;
          if (res.errorCode == that.$msg.responseCode.success) {
            that.list.datas = res.data.records;
            that.list.pagination.total = Number(res.data.total);
          } else {
            that.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          that.isloading = false;
        });
    },
    onPageChange(page) {
      that.list.pagination.defaultCurrent = page.current;
      that.list.pagination.pageSize = page.pageSize;
      that.GetList();
    },
    //table 单选
    onRowChange(selectedRowKeys, selectedRows) {
      that.selectRows = selectedRowKeys;
      that.selectdata = selectedRows;
    },
    clearRows() {
      that.selectRows = [];
    },
    async GetCourseTypes() {
      //获取列表
      let result = await that.$api.courseType.getUseTypeTree();
      if (result.errorCode == that.$msg.responseCode.success) {
        that.treeDataCategory = result.data;
        that.chageTreeData(that.treeDataCategory);
      } else {
        that.$message.error(result.errorMsg);
      }
    },
    chageTreeData(item) {
      for (let itemKey in item) {
        item[itemKey].scopedSlots = { title: "custom" };
        if (item[itemKey].children && item[itemKey].children.length > 0) {
          that.chageTreeData(item[itemKey].children);
        }
      }
    },
    selectCategory(val) {
      if (val && val.length > 0) {
        that.where.keyword.typeId = val[0];
        that.GetList();
      }
    },
    searchList() {
      that.list.pagination.defaultCurrent = 1;
      that.GetList();
    },
    //选择并返回
    choseBack() {
      if (that.selectRows) {
        that.$emit("callbackMethod", that.selectdata[0]);
      }
    },
  },
};
</script>
<style>
</style>