<template>
  <div>
    <div class="pxkeji-right-box">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="5">
            <a-form-item label="广告名称">
              <a-input placeholder="请输入" v-model="search.name" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="广告位名称">
              <a-select
                v-model="search.advZoneId"
                placeholder="请选择"
                show-search
                :filter-option="false"
                :not-found-content="null"
                @search="handleSelSearch"
                allowClear
              >
                <a-select-option
                  v-for="item in advZones"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="3" :xl="4">
            <a-form-item label="状态">
              <a-select v-model="search.status" placeholder="请选择" allowClear>
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">已禁用</a-select-option>
                <a-select-option value="1">已启用</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :xxl="3" :xl="4">
            <a-form-item label="资源类型">
              <a-select
                v-model="search.sourceType"
                placeholder="请选择"
                allowClear
              >
                <a-select-option
                  v-for="item in sourceTypes"
                  :key="item.type"
                  :value="item.type"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="4" :style="{ textAlign: 'left' }">
            <a-form-item>
              <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div>
        <pxkeji-btn
          name="添加"
          class="pxkeji_basic_btn"
          @click="showInfo({ id: 0 })"
          type="primary"
          :roles="['广告-广告添加']"
        />
        <pxkeji-btn
          name="批量启用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(1)"
          :roles="['广告-广告启用禁用']"
        />
        <pxkeji-btn
          name="批量禁用"
          :disabled="selectRows.length == 0"
          class="pxkeji_basic_btn"
          @click="changeStatusBatch(0)"
          :roles="['广告-广告启用禁用']"
        />
        <span style="margin-left: 8px">
          <template v-if="selectRows.length > 0">
            {{ `已选择 ${selectRows.length} 项` }}
            <a-button type="link" @click="cancelSelect"> 取消选择 </a-button>
          </template>
        </span>
      </div>
    </div>

    <div>
      <a-table
        rowKey="id"
        size="small"
        bordered
        :columns="list.columns"
        :pagination="list.pagination"
        @change="onPageChange"
        :dataSource="list.datas"
        :loading="isloading"
        :rowSelection="{
          type: 'checkbox',
          columnWidth: 40,
          onChange: onRowChange,
          selectedRowKeys: selectRows,
        }"
        :customRow="customRow"
        :rowClassName="setRowClassName"
        :locale="{ emptyText: '暂无数据' }"
        :scroll="{ y: list.tableHeight }"
      >
        <span slot="count" slot-scope="count">
          <span v-if="count == -1">不限</span>
          <span v-else>{{ count }}</span>
        </span>
        <span slot="sourceType" slot-scope="sourceType">
          <span v-if="sourceType == 0">无动作</span>
          <span v-if="sourceType == 1">指定链接</span>
          <span v-if="sourceType == 2">指定课程</span>
          <span v-if="sourceType == 3">指定班级</span>
          <span v-if="sourceType == 4">指定资讯</span>
          <span v-if="sourceType == 5">指定课程分类</span>
        </span>
        <span slot="status" slot-scope="status">
          <a-tag v-if="status" color="green">已启用</a-tag>
          <a-tag v-else color="red">已禁用</a-tag>
        </span>
        <span slot="action" slot-scope="action">
          <pxkeji-btn
            name="编辑"
            @click="showInfo(action)"
            size="small"
            type="primary"
            :roles="['广告-广告编辑']"
          />
          <pxkeji-btn
            name="上移"
            @click="upOrDown(action.id, 1)"
            size="small"
            :roles="['广告-广告编辑']"
          />
          <pxkeji-btn
            name="下移"
            @click="upOrDown(action.id, 0)"
            size="small"
            :roles="['广告-广告编辑']"
          />
          <a-popconfirm
            title="是否确认禁用此广告"
            @confirm="updateStatus(action.id, 0)"
            @cancel="modalClose"
            v-if="action.status == 1"
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="禁用"
              size="small"
              type="danger"
              :roles="['广告-广告启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm
            title="是否确认启用此广告"
            @confirm="updateStatus(action.id, 1)"
            @cancel="modalClose"
            v-else
          >
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="启用"
              size="small"
              :roles="['广告-广告启用禁用']"
            />
          </a-popconfirm>
          <a-popconfirm @confirm="delInfo(action.id, 0)" @cancel="modalClose">
            <div slot="title">是否确认删除此广告？</div>
            <a-icon slot="icon" type="question-circle-o" />
            <pxkeji-btn
              name="删除"
              size="small"
              type="danger"
              :loading="isloading"
              :roles="['广告-广告删除']"
            />
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <a-modal
      :title="addedit.title"
      v-model="addedit.visible"
      width="60%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
      :afterClose="modalClose"
    >
      <MaterialInfo @callbackMethod="save" :infos="addedit.info" />
    </a-modal>
  </div>
</template>
<script>
import MaterialInfo from "./materialInfo.vue"; //新增编辑

const columns = [
  {
    dataIndex: "name",
    align: "center",
    title: "广告名称",
    ellipsis: true,
    width: 150,
  },
  {
    dataIndex: "advZoneName",
    align: "center",
    title: "所属广告位",
    ellipsis: true,
    width: 150,
  },
  {
    dataIndex: "sourceType",
    align: "center",
    title: "链接资源类型",
    width: 150,
    scopedSlots: { customRender: "sourceType" },
  },
  {
    dataIndex: "effectiveDate",
    align: "center",
    width: 120,
    title: "生效时间",
    ellipsis: true,
  },
  {
    dataIndex: "effectiveDays",
    align: "center",
    width: 120,
    title: "展示时长(天)",
  },
  {
    dataIndex: "updateTime",
    align: "center",
    width: 200,
    title: "更新时间",
    ellipsis: true,
  },
  {
    dataIndex: "status",
    align: "center",
    title: "状态",
    width: 90,
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 230,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "advertmaterial",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          hideOnSinglePage: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 293,
      },
      advZones: [],
      sourceTypes: [],
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      search: {
        name: "",
        status: "",
        advZoneId: "",
        sourceType: null,
      },
      selectRows: [], //选中项编号
      selectRowId: "",
      isloading: false,
    };
  },
  components: {
    MaterialInfo,
  },
  mounted: function () {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 293;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getSourceType();                  
    _this.getUsableAdvZone();
    _this.GetList();
  },
  methods: {
    async getSourceType() {
      // 获取资源类型
      let result = await _this.$api.advertisement.getSourceTypes();
      console.log(result);
      _this.sourceTypes = result.data;
    },
    async getUsableAdvZone(name) {
      // 获取广告位名称列表
      let result = await _this.$api.adzone.getUsableList(name);
      console.log(result);
      _this.advZones = result.data;
    },
    handleSelSearch(val) {
      _this.getUsableAdvZone(val);
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      _this.$api.advertisement
        .getPageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          _this.search.advZoneId,
          _this.search.name,
          _this.search.status,
          _this.search.sourceType
        )
        .then((res) => {
          console.log(res);
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
      _this.cancelSelect();
    },
    onPageChange(page) {
      //页面切换回调
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      _this.GetList();
    },
    onRowChange(selectedRowKeys) {
      // 选项发生变化回调
      _this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            _this.selectRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      return _this.selectRowId == record.id ? "selectRow" : "";
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.GetList();
    },
    showInfo(info) {
      //打开弹出框
      _this.addedit.visible = true;
      _this.addedit.title = info.id ? "编辑" : "新增";
      _this.addedit.info = info;
    },
    changeStatusBatch(status) {
      let msg = status == 1 ? "启用" : "禁用";
      _this.$confirm({
        title: "提示",
        content: `您确定要${msg}已选择的广告吗？`,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.updateStatus(_this.selectRows.join(","), status);
        },
        onCancel() {},
      });
    },
    /*禁用启用 */
    updateStatus(ids, status) {
      _this.isloading = true;
      _this.$api.advertisement
        .updateStatus(status, ids)
        .then((res) => {
          console.log(res);
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    upOrDown(id, up) {
      _this.isloading = true;
      _this.$api.advertisement
        .upOrDown(id, up)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      _this.$api.advertisement
        .del(id)
        .then((res) => {
          if (res.errorCode == _this.$msg.responseCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.isloading = false;
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    modalClose() {
      setTimeout(() => {
        _this.selectRowId = "";
      }, 3000);
    },
    save() {
      _this.addedit.visible = false;
      _this.GetList();
    },
  },
};
</script>

<style>
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}

#components-form-demo-advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>

