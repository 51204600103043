<template>
  <a-spin :tip="loadingTip" :spinning="isloading">
    <div>
      <a-form-model
        ref="advForm"
        :rules="rules"
        :model="info"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="所属广告位" prop="advZoneId">
              <a-select
                v-model="info.advZoneId"
                placeholder="请选择"
                show-search
                :filter-option="false"
                :not-found-content="null"
                @search="handleSelSearch"
                @change="handleZoneChange"
                allowClear
              >
                <a-select-option
                  v-for="item in advZones"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="广告名称" prop="name">
              <a-input
                placeholder="请输入"
                v-model="info.name"
                :maxLength="50"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="素材上传" prop="advUrl" :help="helpWord">
              <a-input hidden v-model="info.advUrl"></a-input>
              <a-upload
                accept="image/png,image/jpeg"
                :action="$api.file.uploadFile"
                listType="picture-card"
                :beforeUpload="beforeUpload"
                :fileList="fileList"
                @preview="handlePreview"
                @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">Upload</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="info.advUrl" />
              </a-modal>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="生效时间">
              <a-date-picker
                v-model="info.effectiveDate"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                type="date"
                placeholder="请选择"
                style="width: 100%"
              />
            </a-form-model-item>
          </a-col>
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="展示时长" prop="effectiveDays">
              <a-input
                placeholder="请输入"
                v-model.number="info.effectiveDays"
                :maxLength="4"
              >
                <span slot="suffix">天</span>
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="链接资源类型"
              :label-col="{ xxl: 3, xl: 6 }"
              :wrapper-col="{ span: 18 }"
              prop="sourceType"
            >
              <a-radio-group v-model="info.sourceType" @change="onTypeChange">
                <template v-for="item in sourceTypes">
                  <a-radio v-if="item.show" :key="item.type" :value="item.type">
                    {{ item.name }}
                  </a-radio>
                </template>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="info.sourceType == 1">
          <a-col :span="24">
            <a-form-model-item
              label="链接地址"
              :label-col="{ xxl: 3, xl: 6 }"
              :wrapper-col="{ xxl: 20, xl: 16 }"
              prop="linkUrl"
            >
              <a-input placeholder="请输入" v-model="info.linkUrl"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="info.sourceType == 5">
          <a-col :xxl="12" :xl="24">
            <a-form-model-item label="课程分类" prop="linkUrl">
              <a-select
                v-model="info.linkUrl"
                placeholder="请选择"
                show-search
                :filter-option="false"
                :not-found-content="null"
                allowClear
              >
                <a-select-option
                  v-for="item in treeData"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="[2, 3, 4].indexOf(info.sourceType) > -1">
          <a-col :span="24">
            <a-form-model-item
              :label="sourceTypeName"
              :label-col="{ xxl: 3, xl: 6 }"
              :wrapper-col="{ xxl: 20, xl: 16 }"
              prop="linkUrl"
            >
              <a-input hidden v-model="info.linkUrl"></a-input>
              <a-tag style="font-size: 14px" v-show="linkName != ''">{{
                linkName
              }}</a-tag>
              <a-button type="link" @click="targetChose">
                {{ selSourceBtn }}
              </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="状态"
              :label-col="{ xxl: 3, xl: 6 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-switch
                v-model="info.status"
                checked-children="启用"
                un-checked-children="禁用"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item
          :wrapper-col="{ span: 14, offset: 6 }"
          style="margin-top: 40px"
        >
          <pxkeji-btn name="保存" type="primary" @click="handleSubmit" />
          <pxkeji-btn
            name="取消"
            type="default"
            @click="cancelClick"
            class="cancelBtn"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <!--指定课程-->
      <a-modal
        title="指定课程"
        v-model="course.visible"
        width="80%"
        :footer="false"
        destroyOnClose
      >
        <coureListInfo @callbackMethod="getCourseId" :id="info.linkUrl" />
      </a-modal>
      <!--指定资讯-->
      <a-modal
        title="指定资讯"
        v-model="article.visible"
        width="80%"
        :footer="false"
        destroyOnClose
      >
        <articleListInfo @callbackMethod="getArticleId" :id="info.linkUrl" />
      </a-modal>
      <!--指定班级-->
      <a-modal
        title="指定班级"
        v-model="trainClass.visible"
        width="80%"
        :footer="false"
        destroyOnClose
      >
        <trainClassList @callbackMethod="getClassId" :id="info.linkUrl" />
      </a-modal>
    </div>
  </a-spin>
</template>

<script>
let _this;
import coureListInfo from "./courseListInfo.vue"; //指定课程
import articleListInfo from "./articleListInfo.vue"; //指定资讯
import trainClassList from "./trainClassList.vue"; //指定班级
export default {
  name: "metarialInfo",
  props: {
    infos: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let checkLinkUrl = (rule, value, callback) => {
      if (this.info.sourceType == 1 && value == "") {
        callback(new Error("请输入链接地址"));
      } else if ([2, 3, 4].indexOf(this.info.sourceType) > -1 && value == "") {
        callback(new Error("请选择关联资源"));
      } else {
        callback();
      }
    };
    let checkNum = (rule, value, callback) => {
      if (value) {
        if (!Number.isInteger(value)) {
          callback(new Error("请输入数字"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      advZones: [],
      sourceTypes: [],
      info: {
        type: 1,
        sourceType: 0,
        linkUrl: "",
        effectiveDate: "",
        effectiveDays: "",
        status: true,
      },
      helpWord: "",
      treeData: [],
      rules: {
        advZoneId: [
          { required: true, message: "请选择所属广告位", trigger: "change" },
        ],
        name: [{ required: true, message: "请输入广告名称", trigger: "blur" }],
        advUrl: [
          { required: true, message: "请上传广告素材", trigger: "change" },
        ],
        sourceType: [
          { required: true, message: "请选择资源类型", trigger: "change" },
        ],
        linkUrl: [{ validator: checkLinkUrl, trigger: "change" }],
        effectiveDays: [{ validator: checkNum, trigger: "change" }],
      },
      isloading: false,
      loadingTip: "加载中...",
      sourceTypeName: "",
      selSourceBtn: "",
      linkName: "",
      fileList: [],
      previewVisible: false,
      course: {
        visible: false,
      },
      article: {
        visible: false,
      },
      trainClass: {
        visible: false,
      },
    };
  },
  components: {
    coureListInfo,
    articleListInfo,
    trainClassList,
  },
  mounted() {
    _this = this;
    _this.getUsableAdvZone();
    _this.getSourceType();
    if (_this.infos.id) {
      _this.getInfo();
    }
  },
  methods: {
    async getSourceType() {
      let result = await _this.$api.advertisement.getSourceTypes();
      _this.sourceTypes = result.data;
      _this.sourceTypes.forEach((item) => {
        item.show = true;
        if (
          item.name == "指定商品" ||
          item.name == "指定课程分类" ||
          item.name == "指定班级"
        ) {
          item.show = false;
        }
      });
      console.log(_this.sourceTypes);
    },
    async getCourseTypeTree() {
      let result = await _this.$api.courseType.getUseTypeTree();
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.treeData = result.data;
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    async getUsableAdvZone(name) {
      let result = await _this.$api.adzone.getUsableList(name);
      _this.advZones = result.data;
    },
    handleSelSearch(val) {
      _this.getUsableAdvZone(val);
    },
    handleZoneChange(val) {
      let selZone = _this.advZones.find((item) => item.id == val);
      _this.helpWord = "注：图片建议尺寸" + selZone.size;
    },
    onTypeChange(e) {
      _this.linkName = "";
      let val = e.target.value;
      _this.$refs.advForm.clearValidate("linkUrl");
      if (val == 2) {
        _this.sourceTypeName = "课程名称";
        _this.selSourceBtn = "选择课程";
        return;
      }
      if (val == 3) {
        _this.sourceTypeName = "班级名称";
        _this.selSourceBtn = "选择班级";
        return;
      }
      if (val == 4) {
        _this.sourceTypeName = "资讯标题";
        _this.selSourceBtn = "选择资讯";
        return;
      }
      if (val == 5) {
        _this.getCourseTypeTree();
        return;
      }
    },
    targetChose() {
      if (_this.info.sourceType == 2) {
        _this.course.visible = true;
        return;
      }
      if (_this.info.sourceType == 3) {
        _this.trainClass.visible = true;
        return;
      }
      if (_this.info.sourceType == 4) {
        _this.article.visible = true;
        return;
      }
    },
    /**数据回显 */
    getInfo() {
      _this.isloading = true;
      _this.$api.advertisement
        .getById(_this.infos.id)
        .then((res) => {
          _this.isloading = false;
          if (res.errorCode == this.$msg.responseCode.success) {
            _this.info = res.data;
            _this.info.status = _this.$commen.changeIntBoolean(res.data.status);
            if (_this.info.sourceType == 2) {
              _this.sourceTypeName = "课程名称";
              _this.selSourceBtn = "选择课程";
              _this.getCourseInfo(_this.info.linkUrl);
            }
            if (_this.info.sourceType == 3) {
              _this.sourceTypeName = "班级名称";
              _this.selSourceBtn = "选择班级";
              _this.getTrainClassInfo(_this.info.linkUrl);
            }
            if (_this.info.sourceType == 4) {
              _this.sourceTypeName = "资讯标题";
              _this.selSourceBtn = "选择资讯";
              _this.getArticleInfo(_this.info.linkUrl);
            }
            _this.fileList.push({
              uid: 0,
              name: "img0.png",
              status: "done",
              url: res.data.advUrl,
            });
            _this.handleZoneChange(_this.info.advZoneId);
          } else {
            _this.$message.error(res.errorMsg);
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    handleSubmit() {
      _this.$refs.advForm.validate((valid) => {
        if (valid) {
          _this.isloading = true;
          _this.loadingTip = "保存中...";
          let formData = {
            ..._this.info,
            effectiveDate: _this.info.effectiveDate
              ? _this
                  .$moment(_this.info.effectiveDate)
                  .format("YYYY-MM-DD HH:mm:ss")
              : "",
            status: _this.$commen.changeBooleanInt(_this.info.status),
          };
          if (_this.infos.id) {
            formData.id = _this.infos.id;
          }
          _this.$api.advertisement
            .saveOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == _this.$msg.responseCode.success) {
                _this.$message.success(res.errorMsg);
                _this.cancelClick();
              } else {
                _this.$message.error(res.errorMsg);
              }
              _this.isloading = false;
            })
            .catch(() => {
              _this.isloading = false;
            });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      _this.previewVisible = false;
    },
    beforeUpload(file) {
      console.log(file);
      let isJPGPNG = false;
      if (file.type === "image/jpeg" || file.type === "image/png") {
        isJPGPNG = true;
      } else {
        isJPGPNG = false;
        _this.$message.error("只能上传jpg/png格式的文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        _this.$message.error("文件不能超过2MB!");
      }
      return isJPGPNG && isLt2M;
    },
    handlePreview() {
      _this.previewVisible = true;
    },
    handleChange(fileInfo) {
      // 上传文件改变时的状态
      _this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "done") {
        if (
          fileInfo.file.response.errorCode != _this.$msg.responseCode.success
        ) {
          _this.$message.error("上传失败");
        } else {
          _this.info.advUrl = fileInfo.file.response.data.path;
        }
      } else if (fileInfo.file.status == "error") {
        _this.$message.error("上传失败");
      } else if (fileInfo.file.status == "removed") {
        _this.fileList = [];
        _this.info.advUrl = "";
      }
    },
    /**取消 */
    cancelClick() {
      _this.$emit("callbackMethod");
    },
    /**指定课程 */
    getCourseId(info) {
      _this.course.visible = false;
      _this.info.linkUrl = info.id;
      _this.linkName = info.name;
    },
    /*指定资讯 */
    getArticleId(info) {
      _this.article.visible = false;
      _this.info.linkUrl = info.id;
      _this.linkName = info.title;
    },
    // 指定班级
    getClassId(info) {
      _this.trainClass.visible = false;
      console.log(info);
      _this.info.linkUrl = info.id;
      _this.linkName = info.name;
    },
    async getTrainClassInfo(id) {
      let result = await _this.$api.trainClass.getById(id);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.linkName = result.data.name;
      }
    },
    async getArticleInfo(id) {
      let result = await _this.$api.article.getById(id);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.linkName = result.data.title;
      }
    },
    async getCourseInfo(id) {
      let result = await _this.$api.course.getById(id);
      if (result.errorCode == _this.$msg.responseCode.success) {
        _this.linkName = result.data.name;
      }
    },
  },
};
</script>
<style>
.anticon-plus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
.anticon-minus-circle {
  margin-left: 10px !important;
  cursor: pointer;
}
</style>